<template>
  <div>
    <table-page :config="config" />
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { receptionCity } from '@/utils/public';

export default {
  name: "serviceItems",
  components: {
    TablePage
  },
  data() {
    return {
      config: {
        /* 表格是否可多选 */
        dataUrl: '/clientUser/userEvaluation/getList?orgShopId=' + this.$route.query.id,
        belongTo: '当前机构：' + this.$route.query.other,
        filter: {
          controls: [
            {
              key: 'time',
              label: '评论时间',
              type: 'dateRange'
            },
            {
              key: 'userName',
              label: '用户名',
              type: 'text'
            },
            {}
          ]
        },
        columns: [
          {
            key: 'commentTime',
            title: '评论时间',
            width: '100px',
            type: 'datetime',
          },
          {
            key: 'userName',
            title: '用户名',
            width: '100px',
          },
          {
            key: 'score',
            title: '评分',
            width: '100px',
          },
          {
            key: 'commentContent',
            title: '简介',
            width: '300px',
            ellipsis: true
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '80px' : '80px'
          }
        ],

        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        checkbox: true,
        actions: [
          {
            key: 'edit',
            text: '新建用户评价',
            type: 'form',
            permission: '/clientUser/userEvaluation/insert',
            config: {
              color: 'primary',
              title: '新建用户评价',
              submitUrl: "/clientUser/userEvaluation/insert",
              submitText: '保存',
              controls: [
              {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  isDisabled: (row, key) => {
                    return true
                  },
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'userProfile',
                  label: '用户头像',
                  tips: "建议上传圆形或正方形；不超过500kb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024*500
                  }
                },
                {
                  key: 'userName',
                  label: '用户名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'commentContent',
                  label: '评论内容',
                  type: 'textarea',
                  required: true,
                  config: {
                  }
                },
                {
                  key: 'commentTime',
                  label: '评论时间',
                  type: 'datetime',
                  required: true,
                  disabledDate: true,
                },
                {
                  key: 'score',
                  label: '评分',
                  type: 'rate',
                  allowHalf: true,
                  required: true,
                },
                {
                  key: 'commentImage',
                  label: '评论图片',
                  tips: "建议上传不超过1mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    length: 3,
                    maxSize: 1024*1000
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'edit',
            text: '删除',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/clientUser/userEvaluation/delete',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条用户评价，是否全部删除? `
            },
            config: {
              title: '删除成功',
              submitUrl: '/clientUser/userEvaluation/delete',
              submitText: '确认',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
              ]
            }
          },
        ],
        /* 表格行操作 */
        rowActions: [
        {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/clientUser/userEvaluation/update',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/clientUser/userEvaluation/get",
              submitUrl: "/clientUser/userEvaluation/update",
              submitText: '保存',
              controls: [
              {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  isDisabled: (row, key) => {
                    return true
                  },
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'userProfile',
                  label: '用户头像',
                  tips: "建议上传圆形或正方形；不超过500kb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024*500
                  }
                },
                {
                  key: 'userName',
                  label: '用户名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'commentContent',
                  label: '评论内容',
                  type: 'textarea',
                  required: true,
                  config: {
                  }
                },
                {
                  key: 'commentTime',
                  label: '评论时间',
                  type: 'datetime',
                  required: true,
                  disabledDate: true,
                },
                {
                  key: 'score',
                  label: '评分',
                  type: 'rate',
                  allowHalf: true,
                  required: true,
                },
                {
                  key: 'commentImage',
                  label: '评论图片',
                  tips: "建议上传不超过1mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    length: 3,
                    maxSize: 1024*1000
                  }
                },
                {}
              ]
            }
          }
        ]
      }
    }
  },
  async mounted() {
  },
  onUnmounted() {

  },
  computed: {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss"></style>